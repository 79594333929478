.footer { background: $darkGray; color: $footerTextGray; padding: 3em 0 4em 0; position: relative; z-index: 30; }

.footer-content { border-top: 2px solid $footerTextGray; border-bottom: 2px solid $footerTextGray; padding: 1.75em 0 1.5em 0; margin: 1.75em 0 0 0; }

.footer-info { padding: 0.5em 0 0.5em 7em; position: relative; }
.footer-info .footer-logo { display: block; position: absolute; left: 0; top: 0; width: 5.9em; height: 4.5em; }
.footer-info p { font-size: 0.8em; }
.footer-info p:last-child { margin-bottom: 0; }

.footer-nav, .footer-nav ul { margin: 0; text-align: right; }
.footer-nav li { margin: 0; background: none; position: relative; font-size: 0.8em; padding: 0 1em; }
.footer-nav li:last-child {  padding-right: 0; }
.footer-nav li a { color: $footerTextGray; text-transform: uppercase; position: relative; display: block; line-height: 2.5em; white-space: no-wrap; text-decoration: none; font-weight: 700; }
.footer-nav li:not(:last-child):before { content: ""; display: block; width: 1em; height: 2px; background: $footerTextGray; position: absolute; right: -0.5em; top: 1.2em; }
.footer-nav li a:hover, .footer-nav li a:active { color: $orange; }
.footer-nav li a:hover:after { content: ""; display: block; width: 100%; height: 2px; background: $orange; position: absolute; left: 0; }

.footer-socialnets { margin-bottom: 0.5em; text-align: right; }
.footer-socialnets li { padding: 0 0 0 0.5em; }
.footer-socialnets a { display: inline-block; position: relative; overflow: hidden; }
.footer-socialnets a:hover:after, .footer-socialnets a:active:after { content: ""; display: block; width: 1.2em; height: 1.25em; background: $white; border-radius: 5px; position: absolute; left: 0; top: 0; z-index: 3; }
.footer-socialnets a.fb:hover, .footer-socialnets a.fb:active { color: $fbColor; }
.footer-socialnets a.twitter:hover, .footer-socialnets a.twitter:active { color: $twitterColor; }
.footer-socialnets .icon { width: 1.25em; height: 1.25em; position: relative; z-index: 5; }

.footer-copy p { margin: 0; font-size: 0.8em; margin-top: 1.75rem; }

.porta { width: 69px; height: 15px; display: block; float: right; color: $footerTextGray; fill: $footerTextGray; }
.porta:hover { color: $white; fill: $white; }

.footer-partners { border-bottom: 2px solid $footerTextGray; padding: 1.75rem 0 0.5rem 0; }
.partners-head { color: $footerTextGray; font-size: 1.2rem; margin-bottom: 1rem; margin-top: 0; line-height: 1; }
.partners .item img { max-height: 50px; width: auto; max-width: none; /*filter: grayscale(100%);*/ }
.partners .item-img { display: flex; align-items: center; }

@media screen and (max-width: $sm) {
	.footer-socialnets .icon { width: 1.5em; height: 1.5em; } 
}

@media screen and (max-width: $xs) {
	.footer-nav, .footer-nav ul { text-align: left; }
	.footer-nav li { width: 50%; padding: 0 1.5em 0 0; }
	.footer-nav li:before { display: none !important; padding: 0; }

	.footer-socialnets { text-align: left; margin-top: 1.5em; }
	.footer-socialnets li { padding: 0 0.75em 0 0; }

	.partners .item { width: auto !important; }
}