.slider ul, .slider li.panel { margin: 0; padding: 0; list-style: none; position: relative; display: block; }
.slider, .slider li.panel { background: none; overflow: hidden; }
.slider li.panel:not(.slide):not(:first-child) { display: none; }

.slider-nav { display: block; text-align: center; width: 100%; }
.slider-nav li { padding: 0; background: none; display: inline-block; font-size: 0; }
.slider-nav li:not(:last-child) { margin-right: 0.6rem; }
.slider-nav li:before { content: none; }
.slider-nav li a { position: relative; width: 12px; height: 12px; display: block; border: 2px solid $orange; background: transparent; text-indent: -80000px; border-radius: 50%; }
.slider-nav li a:after { content: ""; width: 24px; height: 24px; display: block; background: transparent; border-radius: 50%; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); }
.slider-nav li.current a { background: $orange; }

.slider-nav .prev { position: absolute; left: -3em; top: 50%; display: inline-block; width: 2em; height: 2em; transform: translateY(-50%); }
.slider-nav .prev:after { content: ""; position: absolute; right: 0.75em; top: 50%; display: block; width: 1em; height: 1em; border-left: 0.2em solid $orange; border-bottom: 0.2em solid $orange; transform: rotate(45deg) translate3d(-50%,0,1px); transform-origin: 0% 0%; z-index: 10; }
.slider-nav .prev:hover { border-color: $orange; }
.slider-nav .next { position: absolute; right: -3em; top: 50%; display: inline-block; width: 2em; height: 2em; transform: translateY(-50%); }
.slider-nav .next:after { content: ""; position: absolute; left: 1em; top: 50%; display: block; width: 1em; height: 1em; border-right: 0.2em solid $orange; border-top: 0.2em solid $orange; transform: rotate(45deg) translate3d(-50%,0,1px); transform-origin: 0% 0%; z-index: 10; }
.slider-nav .next:hover { border-color: $orange; }

/* News slider */

.news-slider { position: relative; padding-left: 1.5rem; margin-bottom: 1.5rem; }
.news-slider .slider { border-radius: 10px 0 10px 0; overflow: hidden; }
.news-slider .item { width: 100%; margin-bottom: 0; padding-left: 0; }
.news-slider .item-inner { border-radius: 0; }
.news-slider .slider-nav { text-align: right; z-index: 1000; }
.news-slider .slider-nav ol { position: absolute; left: 0; bottom: 0.75rem; width: 100%; padding-right: 0.75rem; margin: 0; }
.news-slider .slider-nav .prev { left: 2.5em; border-radius: 12px 0 12px 0; background-image: url('../svg/src/arrow-left.svg'); }
.news-slider .slider-nav .prev:hover { background-image: url('../svg/src/arrow-left-white.svg'); }
.news-slider .slider-nav .next { right: 1em; border-radius: 0 12px 0 12px; background-image: url('../svg/src/arrow-right.svg'); }
.news-slider .slider-nav .next:hover { background-image: url('../svg/src/arrow-right-white.svg'); }
.news-slider .slider-nav { .prev, .next { width: 2.75rem; height: 2.1rem; background-color: rgba($white, 0.8); background-repeat: no-repeat; background-size: 1.65rem auto; background-position: center center; } }
.news-slider .slider-nav { .prev, .next { &:hover, &:after { cursor: pointer; background-color: $orange; } } }
.news-slider .slider-nav { .prev:after, .next:after { display: none; } }

/* Selections slider */

.selection-slider-wrapper { border-left: 20px solid transparent; border-right: 20px solid transparent; overflow: hidden; padding-bottom: 1.15em; }
.selection-slider { position: relative; }
.selection-slider .selection { min-height: 23em; }
.selection-slider .slider, .selection-slider .slider li.panel { overflow: visible !important; }
.selection-slider .slider li.panel { margin-right: 1.5em !important; }
.selection-slider .slider li.panel:not(.panel-current) { opacity: 0.3; }
.selection-slider .slider-nav .prev { height: 100%; width: 100%; left: auto; right: 100%; margin-right: 1.25em; }
.selection-slider .slider-nav .next { height: 100%; width: 100%; right: auto; left: 100%; margin-left: 2.5em; }

/* Playlists slider */

.playlist-slider-wrapper { border-left: 20px solid transparent; border-right: 20px solid transparent; overflow: hidden; }
.playlist-slider { position: relative; margin-bottom: 1.5em; }
.playlist-slider .slider, .playlist-slider .slider li.panel { overflow: visible !important; }
.playlist-slider .slider li.panel { margin-right: 1.5em !important; }
.playlist-slider .slider li.panel:not(.panel-current) { opacity: 0.3; }
.playlist-slider .slider-nav .prev { height: 100%; width: 100%; left: auto; right: 100%; margin-right: 1em; }
.playlist-slider .slider-nav .next { height: 100%; width: 100%; right: auto; left: 100%; margin-left: 2.25em; }

@media screen and (max-width: $sm) {
	.selection-slider { overflow: hidden; }
	.selection-slider .slider-nav .prev { width: 4em; height: 4em; right: auto; left: 0; margin-right: 0; }
	.selection-slider .slider-nav .prev:after { right: auto; left: 2em; }
	.selection-slider .slider-nav .next { width: 4em; height: 4em; left: auto; right: 0; margin-left: 0; }
	.selection-slider .slider-nav .next:after { left: 2.5em; }

	.playlist-slider { overflow: hidden; }
	.playlist-slider .slider-nav .prev { width: 4em; height: 4em; right: auto; left: 0; margin-right: 0; }
	.playlist-slider .slider-nav .prev:after { right: auto; left: 2em; }
	.playlist-slider .slider-nav .next { width: 4em; height: 4em; left: auto; right: 0; margin-left: 0; }
	.playlist-slider .slider-nav .next:after { left: 2.5em; }

	.playlist-slider .playlist { padding: 2em 3.5em; }
}

@media screen and (max-width: $xs) {
	.news-slider .slider-nav { .prev, .next { top: 35%; } }
}