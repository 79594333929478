form.std label { font-family: $baseFont; font-size: 0.9em; color: $black; margin: 0 0 10px 0; display: block; }
form.std input.text { font-family: $baseFont; font-size: 0.9em; color: $black; border: none; width: 100%; background: $white; padding: 0.5em 1em; }
form.std textarea { font-family: $baseFont; font-size: 0.9em; color: $black; resize: vertical; border: none; width: 100%; min-width: 100%; max-width: 100%; height: 150px; min-height: 150px; max-height: 300px; background: $white; padding: 0.5em 1em; }
form.std select { font-family: $baseFont; font-size: 0.9em; color: $black; width: 100%; padding: 2px 10px; }
form.std option { font-family: $baseFont; font-size: 0.9em; padding: 2px 20px; }

form.std input.text[required="required"] { background-image: url('../img/req.png'); background-position: right center; background-repeat: no-repeat; }

/*================================= Form standard =================================*/

/* Newsletter */

.newsletter { color: $white; }
.newsletter input.text { background: #535151; font-size: 0.9em; color: lighten($footerTextGray, 40%); padding: 1.15em 1em; border: none; width: 100%; }
.newsletter input.text:focus { background: $white; color: $baseFontColor; }
.newsletter p { font-size: 0.85em; }
.newsletter-title { color: $white; margin-bottom: 0.1em; }
.newsletter .btn { line-height: 1.4; padding: 0.5em 0.25em 0.5em 0.25em; width: 100%; color: $white; border-color: $white; font-size: 1.2rem; }
.newsletter .btn { &:hover, &:focus { border-color: $orange; } }
.newsletter-container { display: flex; flex-wrap: wrap; }
.newsletter-text { width: 35%; }
.newsletter-input { width: 56%; padding: 0 30px; }
.newsletter-btn { width: 44%; }
.newsletter-check-input { display: none !important; }
#newsletter-form-wrapper { width: 65%; }

/* Search */

.form-search input.text { background: $white; color: $baseFontColor; font-family: $baseFont; font-size: 1.5em; padding: 0.45em 0.75em; width: 100%; border: none; }
.form-search .btn { padding: 0.3em 0.35em 0.4em 0.35em; width: 100%; }

/* Filter */
.form-filter .toggle { position: relative; }
.form-filter .toggle input { visibility: hidden; position: absolute; }
.form-filter { div.checker, div.checker span, div.checker input { width: 100%; height: 100%; position: absolute; left: 0; top: 0; background: none; visibility: hidden; } }

.form-filter .toggle label { display: block; background: rgba($white, 0.5); border-radius: 10px 0 10px 0; padding: 0.5em 0.75em; line-height: 1.1; font-weight: 700; color: $baseFontColor; }
.form-filter .checked .toggle label { background: $black; color: $white; }
.form-filter .toggle label:hover { background: $orange; color: $white; }

/* Standard */

form.std .cell-set { margin: 0 0 0 -20px; }
form.std .cell { margin: 0 0 30px 0; padding-left: 20px; position: relative; text-align: left; }
form.std .cell-input { margin: 0 0 30px 0; padding-left: 20px; position: relative; text-align: left; }

/* Inline */

form.std .set-2 .cell { width: 50%; }
form.std .set-3 .cell { width: 33.3333%; }
form.std .set-4 .cell { width: 25%; }
form.std .set-5 .cell { width: 20%; }

form.std .toggle { position: relative; padding: 0 0 0 25px; }
form.std .toggle label { display: inline-block; *display: inline; zoom: 1; vertical-align: top; margin: 0 0 0 0; }
form.std .toggle .note { font-size: 16px; max-width: 100%; }

/* Various */

form.std .radio,
form.std .checker { position: absolute; left: 0; top: 0; }


@media screen and (max-width: $md) {
	.newsletter-text { width: 38%; }
	.newsletter-input { width: 54%; }
	.newsletter-btn { width: 46%; }
	.newsletter-btn .btn { font-size: 1.25em;  padding: 0.45em 0.35em 0.55em 0.35em; }

	#newsletter-form-wrapper { width: 80%; }
	.newsletter-input { padding-left: 0; }
}

@media screen and (max-width: $sm) {
	.newsletter-text { width: 100%; }
	#newsletter-form-wrapper { width: 100%; }
	.newsletter-input { width: 60%; padding-left: 0; }
	.newsletter-btn { width: 40%; }
}

@media screen and (max-width: $xs) {
	.newsletter-input { width: 100%; padding-right: 0; }
	.newsletter-btn { width: 100%; margin-top: 1em; text-align: left; }
	.newsletter-btn .btn { width: auto; }
}

@media screen and (max-width: $xxs) {

}