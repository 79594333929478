.content { flex: 1 0 auto; padding: 0; position: relative; padding-top: 3em; overflow: hidden; }
.content:before { content: ""; display: block; width: 2200px; min-width: 130vw; height: 200vh; background: $beige; border-radius: 50%; position: absolute; left: 50%; top: -100vh; z-index: 1; transform: translateX(-50%); }

/* Block */

.block { padding: 3.5em 5em 3.5em 6em; background: $bgGray; }
.block-content { position: relative; z-index: 5; }

.block.block-narrow { padding: 2em 5em 2em 6em; }

.block.block-alt { background: $white; }

.block.block-alpha { padding-top: 0; }
.block.block-omega { padding-bottom: 0; }

.block-intro-bg { position: relative; }
.block-intro-bg:before { content: ""; display: block; width: 18.5em; height: 18.5em; background: url('../images/bg-circles.svg') 0 0 no-repeat; background-size: 100% 100%; position: absolute; right: -10em; top: 2.5em; z-index: 2; }
.block-intro-bg:after { content: ""; display: block; width: 21.5em; height: 12.75em; background: url('../images/bg-triangles.svg') 0 0 no-repeat; background-size: 100% 100%; position: absolute; left: -12.5em; top: 100vh; margin-top: -9em; z-index: 2; }
.alt-bg .block-intro-bg:after { top: 55vh; }
.alt-bg-2 .block-intro-bg:after { top: 34vh; }

.section-head { text-align: center; }

.box { background: $white; padding: 1.5em; margin-bottom: 1.5em; border-radius: 10px 0 10px 0; }

.btn-more-wrap { margin: 0.5em 0 1em 0; }

.side-nav-box { background: $orange; color: $white; border-radius: 10px 0 10px 0; padding: 1em; margin-right: 1.25em; margin-bottom: 1.5em; }
.side-nav { margin-bottom: 0; padding: 0; list-style-type: none; }
.side-nav li { padding: 0; margin: 0; }
.side-nav > li > a { font-size: 0.9em; display: block; width: 100%; font-weight: 700; text-decoration: none; color: $white; border-bottom: 2px solid rgba($white, 0.5); transition: all 0.1s ease-in; padding: 0.6em 0; }
.side-nav > li > a { &:hover, &:active { text-decoration: none; color: $white; border-color: rgba($white, 1); } }
.side-nav > li:last-child > a { border-bottom: none; }

/* Teaser */

.teaser { background: $white; }

/* Content */

.item-list { margin: 0 0 0 -1.5em; display: flex; flex-direction: row; flex-wrap: wrap; }
.item-list-2 .item { width: 50%; }
.item-list-3 .item { width: 33.33333%; }
.item-list-4 .item { width: 25%; }

.item { display: flex; align-items: stretch; margin: 0 0 1.5em 0; padding-left: 1.5em; position: relative; }
.item-inner { position: relative; width: 100%; background: $white; color: $white; font-weight: 700; border-radius: 10px 0 10px 0; overflow: hidden; display: flex; flex-direction: column; justify-content: flex-end; text-decoration: none; transition: all 0.08s ease-out; z-index: 10; }
.item-inner:after { content: ""; display: block; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba($darkGray,0) 0%, rgba($darkGray,0) 30%, rgba($darkGray,0.5) 70%, rgba($darkGray,1) 99%); position: absolute; left: 0; top: 0; z-index: 18; }
.item-inner .item-bg { position: absolute; left: 50%; top: 0; transform: translateX(-50%); max-width: none; width: auto; min-width: 100%; min-height: 100%; z-index: 15; }
.item-inner .item-text { padding: 1.5em 2.5em; position: relative; z-index: 20; }
.item-inner .item-title { color: $white; text-decoration: none; margin-bottom: 0.4em; }
.item-desc { color: $gray; font-size: 0.6em; text-transform: uppercase; margin-bottom: 0; }
.item-inner a { text-decoration: none; }
.item-desc a:hover, .item-desc a:active { text-decoration: underline; color: $orange; }
.item-link { display: block; }

.item-clickable { &:hover, &:active { color: $white; text-decoration: none; transform: translateY(-8px); box-shadow: $hoverBoxShadow; transition: all 0.05s ease-in; cursor: pointer; }}
.item-inner { &:hover .item-title, &:active .item-title { color: $orange; text-decoration: underline; }}

.item-no-img .item-title { color: $baseFontColor; }
.item-no-img .item-inner { color: $baseFontColor; }
.item-no-img .item-inner:after { display: none; }

.artists .item-title { font-size: 1.5em; }
.artists .item-list-3 .item-inner { min-height: 16em; }
.artists .item-list-4 .item-inner { min-height: 11.5em; }
.artists .item .item-inner:after { background: linear-gradient(to bottom, rgba($black,0) 0%, rgba($black,0) 30%, rgba($black,0.4) 80%, rgba($black,0.7) 99%); }

.banners .item-title { font-size: 1.5em; }
.banners .item-list .item-inner { min-height: 14.75em; }
.banners .item--nobg .item-inner { color: $black; }
.banners .item--nobg .item-inner:after { display: none; }
.banners .item-bg { width: 100%; }
.banners .item-inner { &:hover .item-title, &:active .item-title { text-decoration: none; }}


.workshops .item-inner { justify-content: flex-start; }
.workshops .item-text { padding: 1.5em 2em; }
.workshops .item-title { font-size: 1.2em; }
.workshops .item-desc { text-transform: none; font-size: 0.8em; margin-top: 1em; font-weight: 400; }

.news .item { width: 25%; }
.news .item-inner:before { content: "+"; display: block; color: $orange; position: absolute; right: 0.55em; top: 0.2em; transform: scale(0.9, 0.95); z-index: 20; }
.news .item:not(.item-double) .item-title { font-size: 0.9em; margin-bottom: 0.75em; }
.news .item:not(.item-double) .item-text { padding: 1em 1.25em; }
.news .item:not(.item-double) .item-inner:after { background: linear-gradient(to bottom, rgba($darkGray,0) 0%, rgba($darkGray,0) 10%, rgba($darkGray,0.5) 40%, rgba($darkGray,1) 99%); }
.news .item-inner { min-height: 12em; }
.news .item-double { width: 50%; }
.news .item-double .item-text { padding: 2em 2.5em; }
.news .item-double .item-inner { min-height: 25.5em; }
.news .item-double .item-inner:before { transform: scale(1.5); right: 0.65em; }
.news .items-wrap { width: 50%; display: flex; flex-direction: row; flex-wrap: wrap; }
.news .items-wrap .item { width: 50%; }
.news .items-slider { width: 50%; }

.tags { margin: 0; padding: 0; }
.tags li { display: inline-block; font-size: 0.8em; margin: 0.15em 0.5em 0.15em 0; padding: 0; }
.tags li:last-child { margin-right: 0; }
.tags li a { display: inline-block; text-decoration: none !important; background: rgba($white, 0.5); color: rgba($white, 0.7); padding: 0.5em 0.75em; line-height: 1.1; font-weight: 700; border-radius: 10px 0 10px 0; }
.tags li a:hover { background: $orange; color: $white; }

.tags-alt li a, .item-no-img .tags li a { background: $beige; color: $darkBeige; }
.tags-alt-2 li a { background: $white; color: $darkBeige; }
.tags-alt-2 li a.selected { background: $black; color: $gray; }

.selection { min-height: 14em; }
.selection:before { content: "+"; display: block; color: $orange; position: absolute; right: 0.55em; top: 0.2em; font-size: 1.5em; z-index: 20; }

.playlist { background: $white; padding: 2em 2.5em; border-radius: 10px 0 10px 0; }
.playlist-header .playlist-title { display: inline-block; line-height: 1.2; font-size: 1.5em; margin-bottom: 0.75em; }
.playlist-header .tags { display: inline-block; transform: translateY(-0.2em); float: right; margin-bottom: 1em; }
.playlist-iframe p { margin: 0; text-align: center; }

/* About / contact */

.contacts .item-inner { justify-content: flex-start; color: $baseFontColor; }
.contacts .item-inner:after { display: none; }
.contacts .contact-img { border-radius: 10px 0 0 0; overflow: hidden; height: 13em; position: relative; }
.contacts .contact-img img { position: absolute; max-width: none; width: auto; min-width: 100%; min-height: 100%; left: 50%; top: 0; transform: translateX(-50%); }
.contacts .contact-text { border: 1px solid darken($bgGray, 5%); border-radius: 0 0 10px 0; border-top: none; padding: 1.25em 1.25em 1em 1.25em; }
.contacts .contact-text p:last-child { margin-bottom: 0; }
.contacts .contact-text a:hover { text-decoration: underline; }
.contacts .contact-name { font-size: 1.2em; margin-bottom: 0.4em; }
.contacts .contact-job { font-size: 0.6em; color: $gray; text-transform: uppercase; }
.contacts .contact-info { color: $orange; font-weight: 400; font-size: 0.8em; }
.contacts .item-no-img .contact-text { border-top: 1px solid darken($bgGray, 5%); border-radius: 10px 0 10px 0; }

.contact-box-wrapper { pointer-events: none; }
.contact-box { background: $orange; border-radius: 10px 0 10px 0; color: $white; font-weight: 700; padding: 2em; pointer-events: auto; }
.contact-box hr { height: 2px; border-top: 2px solid $black; margin: 0.8em 0; }
.contact-box p, .contact-box ul { margin-bottom: 0.8em; }
.contact-box  a { text-decoration: none; }
.contact-box a:hover { color: $white; text-decoration: underline; }
.contact-head { color: $black; font-size: 3em; line-height: 1; margin-bottom: 0.35em; }

/* News */

.article-image { margin-bottom: 2.25em; border-radius: 10px 0 10px 0; overflow: hidden; max-height: 30em; }
.article-image img { width: 100%; }
.article-image.article-image-small { max-height: none; }
.article-gallery { margin-top: 1.75em; margin-bottom: 0.75em; }
.article .row:last-child .article-gallery { margin-bottom: 0; }

.article-share-title { font-size: 0.7em; color: $gray; text-transform: uppercase; font-weight: 700; margin-bottom: 0.5em; }
.article-share li { padding: 0; margin-bottom: 0.5em; }
.article-share li a { display: inline-block; color: $orange; }
.article-share li a:hover { color: $baseFontColor; }
.article-share .icon { width: 1.25em; height: 1.25em;  }

/* Listen */

.filter-category-count { color: $gray; }

.filter-values { padding: 2.75em 0 1.25em 0; color: $darkBeige; text-align: center; font-size: 0.8em; }
.filter-values p, .filter-values ul { vertical-align: middle; }
.filter-values-count { font-weight: 700; margin-right: 1em; }
.filter-selected li { padding: 0 0.5em; vertical-align: middle; }
.filter-selected li a { font-weight: 700; }
.filter-uncheck { transform: translateY(0.13em); display: inline-block; }

.playlists { margin-bottom: 2em; }
.playlists .playlist { margin-bottom: 1em; }

.playlist-small { margin-bottom: 1em; }
.playlist-small iframe { width: 100%; }
.playlist-small p { margin-bottom: 0; }

/* Artist detail */

.artist-intro { background: $black; color: $white; border-radius: 10px 0 10px 0; overflow: hidden; margin-bottom: 1.75em; position: relative; display: flex; align-items: stretch; flex-wrap: wrap; }
.artist-intro:after { content: ""; display: block; width: 100%; height: 100%; background: linear-gradient(to bottom, rgba(#000,0) 0%, rgba(#000,0) 20%, rgba(#000,0.5) 70%, rgba(#000,0.8) 99%); position: absolute; left: 0; top: 0; z-index: 18; }
.artist-intro-text { width: 60%; padding: 3em; min-height: 20em; position: relative; z-index: 20; }
.artist-intro-img { width: 40%; overflow: hidden; position: relative; }
.artist-intro-img img { max-width: none; min-width: 100%; width: auto; min-height: 100%; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }

.artist-name { color: $white; font-size: 3em; line-height: 1.2; margin-bottom: 0.5em; }
.artist-prev, .artist-next { color: $orange; display: inline-block; margin-right: 0.5em; }
.artist-prev:hover, .artist-next:hover { color: $white; }
.artist-prev .icon, .artist-next .icon { width: 1.5em; height: 1.5em; }

.detail-text { padding-left: 3em; }

.detail { font-size: 0.8em; margin-bottom: 1.25em; }
.detail:last-child { margin-bottom: 0; }
.detail-title { color: $orange; text-transform: uppercase; margin-bottom: 0.3em; }
.detail .tags { margin-bottom: 1em; font-size: 1.25em; }
.detail li { padding: 0; }
.detail a:not(.btn) { text-decoration: underline; }
.detail a:hover, .detail a:active { text-decoration: none; }
.detail-socialnets .icon { width: 1.3em; height: 1.3em; margin-right: 0.75em; }
.detail-socialnets svg { pointer-events: auto !important; }
.detail p:last-child { margin-bottom: 0; }


.gallery .item { width: 33.33333%; height: 12em; overflow: hidden; position: relative; margin-bottom: 1.5em; }
.gallery .item a { position: relative; display: block; width: 100%;	height: 100%; overflow: hidden; }
.gallery .item img { max-width: none; width: auto; min-width: 100%; min-height: 100%; position: absolute; left: 50%; top: 0; transform: translateX(-50%); }
.gallery .item-double { width: 66.66666%; height: 25.5em; }
.gallery .items-wrap { width: 33.33333%; position: relative; display: flex; flex-direction: row; flex-wrap: wrap; }
.gallery .items-wrap .item { width: 100%; }
.gallery .icon-play { position: absolute; position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); fill: $white; z-index: 50; width: 2.5em; height: 2.5em; transition: fill 0.15s ease-in; }
.gallery a:hover .icon-play { fill: $orange; }

.gallery.gallery-workshop .item { width: 50%; }
.gallery.gallery-workshop .item-double { width: 100%; }

.lector { position: relative; min-height: 5em; margin-bottom: 1.5em; }
.lector.lector-image { padding-left: 6.5em; }
.lector-img { position: absolute; left: 0; top: 0; width: 5em; height: 5em; border-radius: 50%; overflow: hidden; }
.lector-img img { max-width: none; width: auto; min-width: 100%; min-height: 100%; }

/* Industry */

.map-wrap { margin: 1em 0 0 0; }
.map { width: 30em; height: 18em; max-width: 100%; pointer-events: auto; margin: 0 auto; display: block; stroke-width: 3px; position: relative; z-index: 100; }
.map:hover { cursor: pointer; }
.map .region { fill: $white; stroke: $beige; transition: all 0.15s ease-in; }
.map .region-active { fill: $black; }
.map .region:hover { fill: $orange; stroke: $orange; }

.subjects { margin-bottom: 2.25em; }
.subject { padding: 0; background: $white; color: $baseFontColor; border-radius: 10px 0 10px 0; position: relative; margin-bottom: 1em; }
.subject-img { pointer-events: none; position: absolute; left: 1.35em; top: 1.5em; width: 5em; height: 5em; border-radius: 50%; overflow: hidden; }
.subject-img img { max-width: none; width: auto; min-width: 100%; min-height: 100%; }
.subject .icon-link { width: 1em; height: 1em; transform: translateY(2px); }

.subject-head { padding-right: 9em; padding: 1.5em 1.5em 1.5em 8em; min-height: 8em; }
.subject-head:before { pointer-events: none; content: "+"; display: block; color: $orange; position: absolute; right: 1em; top: 0.75em; transform: scale(1.5, 1.6); transition: transform 0.2s ease-in; font-weight: 700; z-index: 20; }
.subject-head .tags { margin-bottom: 0; }
.subject-name { font-size: 1em; line-height: 1.5; font-weight: 700; margin-bottom: 0.5em; width: 70%; }
.subject-map { position: absolute; right: 2em; top: 2em; width: 7em; height: 4em; }
.subject-map img { width: 7em; height: 4em; }

.subject-content { display: flex; flex-direction: row; align-items: stretch; max-height: 0; overflow: hidden; transition: max-height 0.1s ease-out; padding: 0 1.5em 0 8em; }
.subject-text { width: 65%; border-right: 2px solid $beige; padding-right: 2em; margin: 0 0 2.5em 0; }
.subject-text .entry { font-size: 1em; margin-bottom: 0.75em; }
.subject-details { width: 35%; padding-left: 2.25em; margin: 0 0 2.5em 0; }

.subject:hover { box-shadow: $hoverBoxShadow; }
.subject:hover .subject-title { color: $orange; }
.subject-head:hover { cursor: pointer; }
.subject-head:hover:before { color: $black; }

.subject-opened .subject-content { max-height: 5000px; transition: max-height 0.7s ease-in; }
.subject-opened .subject-head:before { transform: rotate(45deg) scale(1.5, 1.6); }

/* Programmes */

.programmes-list { margin-bottom: 2em; }
.program { background: $white; min-height: 7em; margin-bottom: 2px; display: flex; align-items: stretch; flex-wrap: wrap; position: relative; }
.program:hover, .program:active { text-decoration: none; color: $black; }
.program:hover, .program:active { box-shadow: $hoverBoxShadow; transition: all 0.05s ease-in; }

.program-img { position: relative; width: 16.66666%; overflow: hidden; }
.program-img:after { content: ""; display: block; width: 100%; height: 100%; background: $black; position: absolute; left: 0; top: 0; opacity: 0.5; transition: opacity 0.1s ease-in; z-index: 3; }
.program-img img { position: absolute; width: auto; max-width: none; min-width: 100%; min-height: 100%; left: 50%; top: 0; transform: translateX(-50%); filter: grayscale(100%); }
.program:hover .program-img img, .program:active .program-img img { filter: none; }
.program:not(.program-no-img):hover .program-img:after { opacity: 0; }
.program-date { position: relative; z-index: 20; color: $white; padding: 1em 0.75em;  display: flex; flex-wrap: wrap; flex-direction: row; height: 100%; justify-content: flex-start; align-items: flex-start; }
.program-date .tags { align-self: flex-end; width: 100%; }
.program-date p { margin-bottom: 0.25em; }
.program-date .program-date-big { font-size: 1.4em; line-height: 1.2; }
.program-text { width: 83.33333%; padding: 1em 1.25em; color: $gray; font-size: 0.9em; line-height: 1.5; }
.program-text p:last-child { margin-bottom: 0; }
.program-title { color: $black; font-size: 1rem; font-weight: 800; }
.program:hover .program-title, .program:active .program-title { color: $orange; text-decoration: underline; }
.program:hover.item-no-img .program-date { color: $black; }

.programmes-list .program-img { width: 33.33333%; }
.programmes-list .program-text { width: 66.66666%; }

.sh-trig-head { display: block; }
.sh-trig-text { position: relative; display: inline-block; padding-right: 1.1em; background: url('../svg/src/icon-plus-black.svg') right center no-repeat; background-size: 0.9em 0.9em; }
.sh-trig-head:hover .sh-trig-text { background: url('../svg/src/icon-plus-orange.svg') right center no-repeat; background-size: 0.9em 0.9em; }
.sh-trig.active .sh-trig-text { background: url('../svg/src/icon-minus-black.svg') right center no-repeat; background-size: 0.9em 0.9em; }
.sh-trig.active:hover .sh-trig-text { background: url('../svg/src/icon-minus-orange.svg') right center no-repeat; background-size: 0.9em 0.9em; }

.sh-trig-text { color: $black; }
.sh-trig-head:hover .sh-trig-text { color: $orange; text-decoration: underline; }

/* Learn */

.learn .item { width: 33.33333%; }
.learn .item-inner { padding: 1em 1.5em; text-decoration: none; color: $black; font-weight: 400; }
.learn .item-title { color: $orange; font-size: 1.2em; }
.learn .item-title .icon { color: $orange; width: 1em; height: 1em; margin-top: 0.15em; }
.learn .item-inner:hover, .learn .item-inner:active { background: $orange; color: $white; }
.learn .item-inner:hover, .learn .item-inner:active { .item-title { color: $white; text-decoration: none; } }
.learn .item-inner:hover, .learn .item-inner:active { .icon { fill: $white; } }
.learn .item-inner:after { display: none; }

/* Gmap */

.gmap { width: 100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 3; }
.gmap img { max-width: inherit; }

.popup-contact { background: $white; color: $black; width: 190px; height: 80px; padding: 0.75em; position: relative; z-index: 20; }
.popup-contact ul { margin: 0; }
.popup-contact ul li { padding: 0; background: none; }



/* Editmode */
.editmode .block.block-intro, .editmode .content { background: $beige; }
.editmode .content:before { display: none; }
.editmode .contacts .item { width: 50%; }


@media screen and (max-width: $md) {
	.tags li { margin: 0.15em 0.5em 0.35em 0; }
}

@media screen and (max-width: $sm) {
	.content { padding-top: 4em;  }
	.block { padding-left: 0; padding-right: 0; }
	.block.block-narrow { padding-left: 0; padding-right: 0; }

	.side-nav-box { margin-right: 0; }

	.article-share li { display: inline-block; margin-right: 0.75em; }
	.article-share .icon { width: 1.5em; height: 1.5em; }

	.detail-socialnets .icon { width: 1.5em; height: 1.5em; }

	.detail-text { padding-left: 0; }
}

@media screen and (max-width: $xs) {
	.item-list-4 .item { width: 50%; }
	.news .item { width: 50%; }
	.news .item-double { width: 100%; }
	.news .items-wrap { width: 100%; }
	.news .items-wrap .item { width: 50%; }
	.news .items-slider { width: 100%; }

	.item-list-3 .item { width: 50%; }

	.gallery .item { width: 50%; }
	.gallery .item-double { width: 50%; height: 12em; }
	.gallery .items-wrap { width: 100%; }
	.gallery .items-wrap .item { width: 50%; }

	.pager .pager-prev { position: relative; margin: 0.5em 0.5em 0 0.5em; min-width: 6em; }
	.pager .pager-next { position: relative; margin: 0.5em 0.5em 0 0.5em; min-width: 6em; }

	.playlist-header .tags { display: block; float: none; }

	.artist-intro-text { width: 100%; min-height: 0; padding: 2em 2.25em 2.25em 2.25em; }
	.artist-name { font-size: 2.5em; }
	.artist-intro-img { width: 100%; height: 16em; }

	.detail { margin-bottom: 1.75em; }

	.filter-values { font-size: 0.95em; }

	.contact-box { margin-bottom: 300px; }

	.map { height: 15em; }

	.subject-head { padding: 7.75em 1.5em 1.5em 1.5em; padding-right: 0; }
	.subject-name { width: 100%; margin-bottom: 1em; }

	.subject-text { width: 60%; }
	.subject-details { width: 40%; }
}

@media screen and (max-width: $xxs) {
	.side-nav-box { max-width: none; }

	.item-list .item { width: 100% !important; }

	.contacts .contact-img { height: 75vw; }
	.contacts .item-no-img .contact-img { height: 0; }

	.news .item:not(.item-double) .item-title { font-size: 1.1em; }

	.pager .pager-prev { font-size: 1.3em; margin: 0.5em 0.25em 0 0.25em; }
	.pager .pager-next { font-size: 1.3em; margin: 0.5em 0.25em 0 0.25em; }

	.map { height: 13em; }

	.subject-text { width: 100%; }
	.subject-details { width: 100%; }
}